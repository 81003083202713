import React from 'react';
import {useControllerProps} from '../../ControllerContext';
import {BillingColumn} from './BillingColumn';
import {ShippingColumn} from './ShippingColumn';
import * as s from './ShippingSection.scss';

export enum ShippingSectionDataHook {
  root = 'ShippingSectionDataHook.root',
}

export function ShippingSection() {
  const {billingAddress} = useControllerProps().thankYouPageStore;
  return (
    <div className={s.section} data-hook={ShippingSectionDataHook.root}>
      <Column>
        <ShippingColumn />
      </Column>
      <Column>{billingAddress && <BillingColumn />}</Column>
    </div>
  );
}

const Column = (props: any) => {
  return <div className={s.column}>{props.children}</div>;
};
