import React from 'react';
import {useLocaleKeys} from '../../../LocaleKeysProvider';
import {useControllerProps} from '../../ControllerContext';
import {ShippingSectionText} from './ShippingSectionText';

export enum ShippingColumnDataHook {
  title = 'ShippingColumnDataHook.title',
  fullName = 'ShippingColumnDataHook.fullName',
  address = 'ShippingColumnDataHook.address',
  phone = 'ShippingColumnDataHook.phone',
}

export const ShippingColumn = () => {
  const localeKeys = useLocaleKeys();
  const {buyerName, address} = useControllerProps().thankYouPageStore;

  return (
    <div>
      <ShippingSectionText
        dataHook={ShippingColumnDataHook.title}
        text={localeKeys.thankYouPage.shippingAddress.label()}
        isTitle={true}
      />
      <ShippingSectionText dataHook={ShippingColumnDataHook.fullName} text={buyerName} />
      <ShippingSectionText dataHook={ShippingColumnDataHook.address} text={'Missing address key'} />
      <ShippingSectionText dataHook={ShippingColumnDataHook.phone} text={address.phone} />
    </div>
  );
};
