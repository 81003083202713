import React from 'react';
import {useLocaleKeys} from '../../../LocaleKeysProvider';
import {useControllerProps} from '../../ControllerContext';
import {ShippingSectionText} from './ShippingSectionText';

export enum BillingSectionDataHook {
  title = 'BillingSectionDataHook.title',
  fullName = 'BillingSectionDataHook.fullName',
  address = 'BillingSectionDataHook.address',
  phone = 'BillingSectionDataHook.phone',
  company = 'BillingSectionDataHook.company',
  email = 'BillingSectionDataHook.email',
}

export const BillingColumn = () => {
  const localeKeys = useLocaleKeys();
  const {billingAddress} = useControllerProps().thankYouPageStore;

  return (
    <div>
      <ShippingSectionText
        dataHook={BillingSectionDataHook.title}
        text={localeKeys.thankYouPage.billingInfo.label()}
        isTitle={true}
      />
      <ShippingSectionText dataHook={BillingSectionDataHook.fullName} text={billingAddress.fullName} />
      <ShippingSectionText dataHook={BillingSectionDataHook.address} text={'Missing address key'} />
      <ShippingSectionText dataHook={BillingSectionDataHook.phone} text={billingAddress.phoneNumber} />
      <ShippingSectionText dataHook={BillingSectionDataHook.email} text={billingAddress.email} />
      <ShippingSectionText dataHook={BillingSectionDataHook.company} text={billingAddress.company} />
    </div>
  );
};
